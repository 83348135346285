.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: "Monday Feelings";
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

:root {
  --yellow: #ffde08;
  --orange: #fe8505;
  --pink-dark: #ff7fd2;
  --pink-light: #cac0d4;
  --blue: #1dcbff;
  --bg-blue: #6ee6e7;
  --beige: #82fecd;
  --black: #000000;
  --white: #ffffff;
}
